.form {
  display: flex;
}

.searchInput {
  flex-grow: 1;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
}

.searchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
